import React, { useState, useEffect } from "react";
import { PoseableBox } from "components/poseable";
import posed from "react-pose";
import * as easings from "d3-ease";

let loaderWhite = "/loader-white.gif";
let loaderPink = "/loader-pink.gif";
let loaderBlue = "/loader-blue.gif";

function getImage(themeName) {
  switch (themeName) {
    case "blue":
      return loaderBlue;
    case "green":
      return loaderWhite;
    default:
      return loaderPink;
  }
}

const Container = posed(PoseableBox)({
  hidden: {
    applyAtEnd: { display: `none` },
    opacity: 0,
    x: -5,
    transition: {
      duration: 200,
      type: `tween`,
      ease: easings.easeSinIn,
    },
  },
  showing: {
    applyAtStart: { display: `block` },
    opacity: 1,
    x: 0,
    transition: ({ first }) => ({
      delay: first ? 0 : 250,
      duration: 250,
      type: `tween`,
      ease: easings.easeSinOut,
    }),
  },
});

function PageLoader({ theme, showing = false }) {
  const [first, setFirst] = useState(true);
  const [image, setImage] = useState(getImage(theme.name));

  useEffect(() => {
    setImage(getImage(theme.name));
  }, [theme]);

  useEffect(() => {
    setTimeout(() => {
      setFirst(false);
    }, 100);
  }, []);

  return (
    <Container
      pose={showing ? `showing` : `hidden`}
      first={first}
      as={`figure`}
      aria-hidden="true"
      style={{
        position: `fixed`,
        top: 0,
        left: 0,
        width: `100%`,
        height: `100vh`,
        zIndex: 2000,
      }}
    >
      <div
        style={{
          position: `absolute`,
          top: `50%`,
          left: `50%`,
          transform: `translate3d(-50%, -50%, 0)`,
        }}
      >
        <img src={image} width={42} height={37} alt="Loading..." />
      </div>
    </Container>
  );
}

export default PageLoader;
