import {
  DefaultPosedBox,
  PoseableBox,
  PoseableFlex,
} from "components/poseable";
import React, { PureComponent, forwardRef } from "react";

import { Box } from "@rebass/grid";
import BreakpointListener from "components/BreakpointListener";
import { InView } from "react-intersection-observer";
import events from "utils/events";
import { withTheme } from "styled-components";

export const Row = forwardRef((props, ref) => {
  const { multiline = true, style, ...rest } = props;
  return (
    <PoseableFlex
      ref={ref}
      flexWrap={multiline ? `wrap` : null}
      mx={[-1, -2, -3, -4, -5]}
      my={[-1, -2, -3, -4, -5]}
      style={{ position: `relative`, ...style }}
      {...rest}
    />
  );
});

export const Column = forwardRef((props, ref) => {
  const { width, style, fill = false, auto = false, ...rest } = props;
  return (
    <PoseableBox
      ref={ref}
      style={{ position: `relative`, ...style }}
      px={[1, 2, 3, 4, 5]}
      py={[1, 2, 3, 4, 5]}
      flex={fill ? `1 1 auto` : `none`}
      width={width || (fill && auto ? `auto` : `100%`)}
      {...rest}
    />
  );
});

export const Section = withTheme(
  forwardRef(({ style, css, theme, big, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        is="section"
        pt={`0px`}
        pb={big ? theme.gap.big : theme.gap.small}
        style={{
          position: `relative`,
          display: `block`,
          outline: `none !important`,
          ...css,
          ...style,
        }}
        {...props}
      />
    );
  })
);

class PSection extends PureComponent {
  static ID = 0;

  static defaultProps = {
    visibleCondition: true,
  };

  static getDerivedStateFromProps(props, prevState) {
    if (props.visibleCondition !== true) {
      return { canBeVisible: false };
    } else if (props.visibleCondition === true) {
      return { canBeVisible: true };
    }

    if (
      props.visible !== undefined &&
      props.visible !== null &&
      props.visible !== prevState.visible
    ) {
      return {
        visible:
          props.visible === true && prevState.canBeVisible ? true : false,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    PSection.ID++;
    const id = `posed-section-${PSection.ID}`;
    this.state = {
      id,
      visible: false,
      canBeVisible: false,
      timeoutPassed: false,
      isMobile: true,
    };
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ isMobile: newSize < 2 });
  };

  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    this.setState({ isMobile: BreakpointListener.size < 2 });
    if (this.props.autoShow) {
      this.visibleTimeout = setTimeout(() => {
        this.setState({ visible: true });
      }, 2000);
    } else {
      this.initialVisibilityTimeout = setTimeout(() => {
        this.setState({
          timeoutPassed: true,
          canBeVisible: this.props.visibleCondition === true,
        });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.visible &&
      this.state.canBeVisible &&
      prevProps.visibleCondition === true
    ) {
      const bounds = this.base.getBoundingClientRect();
      const { top } = bounds;
      const isOnScreen =
        top <
        window.__smoothScrollY + window.innerHeight - window.innerHeight * 0.25;
      if (this.state.timeoutPassed && isOnScreen) {
        this.setState({ visible: true });
      }
    }

    if (!prevState.visible && this.state.visible) {
      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    clearTimeout(this.initialVisibilityTimeout);
    clearInterval(this.visibleInterval);
  }

  checkVisible = () => {
    if (this.state.canBeVisible) {
      clearInterval(this.visibleInterval);
      this.setVisible();
    }
  };

  onEnter = () => {
    if (!this.state.canBeVisible) {
      this.visibleInterval = setInterval(this.checkVisible, 250);
      return;
    }
    this.setVisible();
  };

  setVisible = () => {
    this.setState({ visible: true });
  };

  render() {
    const {
      style,
      css,
      theme,
      top,
      big,
      small,
      visible: propsVisible,
      autoShow = false,
      children,
      onEnter,
      visibleCondition,
      ...rest
    } = this.props;
    const { id, visible, isMobile } = this.state;

    return (
      <InView
        as={DefaultPosedBox}
        id={id}
        ref={(r) => {
          this.base = r?.node;
        }}
        is="section"
        pt={`0px`}
        pb={big ? theme.gap.big : theme.gap.small}
        initialPose="hidden"
        pose={visible ? `visible` : `hidden`}
        triggerOnce={true}
        rootMargin={isMobile ? `-10% 0%` : `-25% 0%`}
        skip={autoShow}
        onChange={(inView) => {
          if (inView) {
            this.onEnter();
          }
        }}
        style={{
          position: `relative`,
          display: `block`,
          outline: `none !important`,
          ...css,
          ...style,
        }}
        {...rest}
      >
        {children}
      </InView>
    );
  }
}

export const PosedSection = withTheme(PSection);

export const Container = forwardRef(({ css, style, ...props }, ref) => (
  <Box
    ref={ref}
    style={{
      boxSizing: `border-box`,
      position: `relative`,
      display: `block`,
      ...css,
      ...style,
    }}
    m={`0 auto`}
    width={[
      `calc(100% - 40px)`,
      `calc(100% - 60px)`,
      `calc(100% - 80px)`,
      960,
      1200,
      1366,
    ]}
    {...props}
  />
));
