import React from "react";
import TemplateWrapper from "components/TemplateWrapper";
import objectFitImages from "object-fit-images";

import events from "utils/events";
import GlobalEmitter from "utils/GlobalEmitter";

new GlobalEmitter();
// smoothscroll.polyfill();

export const onClientEntry = () => {
  window.__smoothScrollY = 0;
  const testImg = document.createElement(`img`);
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    objectFitImages();
    // console.log(`👍 Object-fit/Object-position are polyfilled`);
  }
};

export const wrapPageElement = ({ props, element }) => {
  return <TemplateWrapper {...props}>{element}</TemplateWrapper>;
};

export const shouldUpdateScroll = () => {
  return false;
};

// Store the window width
window.actualWidth = window.innerWidth;

// Resize Event
window.addEventListener("resize", () => {
  if (window.innerWidth !== window.actualWidth) {
    window.actualWidth = window.innerWidth;
    GlobalEmitter.emit(events.resize, {
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
});
