function getOffset(el) {
  if (!el) {
    return null;
  }
  var _x = 0;
  var _y = 0;
  let width = el.offsetWidth;
  let height = el.offsetHeight;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x, width, height };
}

export default getOffset;
