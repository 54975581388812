import mitt from 'mitt';

class GlobalEmitter {
  static __initted = false;
  static __emitter = null;

  static emit(type, data) {
    if (!GlobalEmitter.__initted) {
      console.warn(`Can't emit yet`);
      return;
    }
    GlobalEmitter.__emitter.emit(type, data);
  }

  static on(type, listener) {
    if (!GlobalEmitter.__initted) {
      console.warn(`Can't listen yet`);
      return;
    }
    return GlobalEmitter.__emitter.on(type, listener);
  }

  static off(type, listener) {
    if (!GlobalEmitter.__initted) {
      console.warn(`Can't remove yet`);
      return;
    }
    return GlobalEmitter.__emitter.off(type, listener);
  }

  constructor() {
    if (GlobalEmitter.__initted) {
      throw new Error('Emitter already initialized');
    }
    GlobalEmitter.__emitter = mitt();
    GlobalEmitter.__initted = true;
  }
}

export default GlobalEmitter;
