import theme from 'theme';
const { breakpoints } = theme;

export default (cssValue, list) => {
  if (!Array.isArray(list)) {
    list = [list];
  }
  let css = `
  ${breakpoints
    .map((bp, idx) => {
      if (idx === 0) {
        bp = `${parseInt(breakpoints[idx + 1]) - 1}px`;
      }
      let val = list[idx] === undefined ? list[list.length - 1] : list[idx];
      val =
        isNaN(val) || cssValue === 'order' || cssValue === 'flex-grow'
          ? val
          : `${val}px`;
      let css = `@media screen and (${
        idx === 0 ? 'max' : 'min'
      }-width:${bp}){${cssValue}:${val};}`;
      return css;
    })
    .join('')}
  `;
  return css;
};
