const colors = {
  white: `#FFFFFF`,
  whiteLineColor: `#F8F8F8`,
  black: `#444444`,
  blackLineColor: `#4A4A4A`,
  blackRevealColor: `#383939`,
  grey: `#f5f5f5`,
  greyLineColor: `#EBEBEB`,
  greyRevealColor: `#DFDFDF`,
  yellow: `#F6DF74`,
  yellowLineColor: `#EFD972`,
  yellowRevealColor: `#FFE363`,
  red: `#ED8270`,
  redLineColor: `#E17C6C`,
  blue: `#6193AE`,
  blueLineColor: `rgba(134, 178, 200, 0.5)`,
  blueRevealColor: `#DFDFDF`,
  green: `#C2D8C6`,
  greenLineColor: `BDD2C1`,
  greenRevealColor: `#B0C8B4`,
};

const fonts = {
  headings: `Druk`,
  body: `Larish`,
  small: `Calibre`,
};

const defaultTheme = {
  colors,
  fonts,
  gap: {
    big: [125, 175, 200, 225, 300, 400],
    small: [75, 100, 125, 150, 250],
    groupedWithNextBlock: [40, 60, 80, 100, 120],
  },
  space: [0, 8, 10, 12, 12, 12],
  breakpoints: ["500px", "768px", "1024px", "1368px", "1920px"],
  textColor: colors.black,
  headingColor: colors.black,
  bgColor: colors.yellow,
  fgColor: colors.red,
  lineColor: colors.yellowLineColor,
  asideLabelColor: colors.black,
  footerLineColor: colors.blackLineColor,
  footerTextColor: colors.white,
  footerHighlightColor: colors.yellow,
  footerBgColor: colors.black,
  revealColor: colors.yellowRevealColor,
  name: `default`,
};

const blueTheme = Object.assign(
  { ...defaultTheme },
  {
    textColor: colors.black,
    headingColor: colors.blue,
    bgColor: colors.grey,
    fgColor: colors.blue,
    lineColor: colors.greyLineColor,
    asideLabelColor: colors.black,
    revealColor: colors.blueRevealColor,
    name: `blue`,
  }
);

const blackTheme = Object.assign(
  { ...defaultTheme },
  {
    textColor: colors.grey,
    headingColor: colors.black,
    bgColor: colors.black,
    fgColor: colors.red,
    lineColor: colors.blackLineColor,
    asideLabelColor: colors.white,
    revealColor: colors.blackRevealColor,
    footerBgColor: colors.red,
    footerLineColor: colors.redLineColor,
    footerTextColor: colors.black,
    footerHighlightColor: colors.grey,
    name: `black`,
  }
);

const blackYellowTheme = Object.assign(
  { ...defaultTheme },
  {
    textColor: colors.grey,
    headingColor: colors.black,
    bgColor: colors.black,
    fgColor: colors.red,
    lineColor: colors.blackLineColor,
    asideLabelColor: colors.white,
    footerBgColor: colors.yellow,
    footerLineColor: colors.yellowLineColor,
    footerTextColor: colors.black,
    footerHighlightColor: colors.grey,
    revealColor: colors.blackRevealColor,
    name: `black-yellow`,
  }
);

const greenTheme = Object.assign(
  { ...defaultTheme },
  {
    textColor: colors.black,
    headingColor: colors.black,
    bgColor: colors.green,
    fgColor: colors.black,
    lineColor: colors.greenLineColor,
    asideLabelColor: colors.black,
    revealColor: colors.greenRevealColor,
    name: `green`,
  }
);

export default defaultTheme;
export { colors, fonts, blueTheme, greenTheme, blackTheme, blackYellowTheme };
