import { Column, Container } from "components/layout";
import React, { Component } from "react";

import { Flex } from "@rebass/grid";
import PropTypes from "prop-types";
import styled from "styled-components";

const Bg = styled(Flex)`
  position: ${(props) => props.position || `fixed`};
  display: block;
  flex-wrap: nowrap;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: ${(props) => props.height || `calc(100vh + 100px)`};
  z-index: -1;

  & > div {
    height: 100%;
  }
`;

const BgColumn = styled(Column)`
  position: absolute !important;
  display: block;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  height: 100%;
  overflow: visible;
  transform: translate3d(${(props) => props.tx * 100}%, 0, 0);

  & figure {
    line-height: 0px;
    position: absolute;
    display: block;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    background-color: ${(props) => props.linecolor || props.theme.lineColor};
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }
`;

const getMobileLines = (props) => {
  const { bgColor, lineColor } = props;
  return BGGrid.MOBILE_ARR.map((n, idx) => {
    return (
      <BgColumn
        key={`bggrid-line-mobile-${idx}`}
        idx={idx}
        width={1 / 4}
        tx={-2 + idx}
        bgcolor={bgColor}
        linecolor={lineColor}
      >
        <figure style={{ height: `100%`, position: `absolute`, top: `0` }} />
      </BgColumn>
    );
  });
};

const getDesktopLines = (props) => {
  const { bgColor, lineColor } = props;
  return BGGrid.DESKTOP_ARR.map((n, idx) => {
    return (
      <BgColumn
        key={`bggrid-line-desktop-${idx}`}
        idx={idx}
        width={1 / 6}
        tx={-4.5 + idx}
        bgcolor={bgColor}
        linecolor={lineColor}
      >
        <figure style={{ height: `100%`, position: `absolute`, top: `0` }} />
      </BgColumn>
    );
  });
};

export default class BGGrid extends Component {
  static NUM_MOBILE = 7;
  static NUM_DESKTOP = 14;
  static MOBILE_ARR = Array.from({ length: BGGrid.NUM_MOBILE });
  static DESKTOP_ARR = Array.from({ length: BGGrid.NUM_DESKTOP });

  static propTypes = {
    mobile: PropTypes.bool,
    position: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    z: PropTypes.number,
  };

  static defaultProps = {
    mobile: false,
    position: null,
    height: null,
    z: -1,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      mobileLines: getMobileLines(nextProps),
      desktopLines: getDesktopLines(nextProps),
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      mobile: props.mobile === true,
      mobileLines: getMobileLines(props),
      desktopLines: getDesktopLines(props),
    };
  }

  render() {
    const {
      mobile = false,
      position = null,
      height = null,
      bgColor,
      z = -1,
    } = this.props;

    const { mobileLines, desktopLines } = this.state;

    return (
      <Bg
        position={position}
        height={height}
        z={z}
        style={{ backgroundColor: bgColor, width: `100%`, overflowX: `hidden` }}
      >
        <Container
          style={{
            overflow: `visible`,
          }}
        >
          {mobile ? mobileLines : desktopLines}
        </Container>
      </Bg>
    );
  }
}
