import minireset from "minireset.css";
export { minireset };

export const globals = `
    html, body{
        margin:0;
        padding:0;
        width:100%;
        height:100%;
        overflow:hidden;
        height:auto;
    }

    body{
        font-family:'Larish';
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-size-adjust: 100%;
        height: 100%;
        @media screen and (min-width:769px){
            ::-webkit-scrollbar {
                width: 0.75rem;
            }
        
            ::-webkit-scrollbar-track {
                box-shadow: inset 1px 0 0px rgba(0, 0, 0, 0.1);
                background-color: #cccccc;
            }
        
            ::-webkit-scrollbar-thumb {
                background-color: #444444;
            }
        }
    }
    .scrollbar-track-y{
        width: 2px !important;
        background-color: #cccccc !important;
    }
    .scrollbar-thumb {
        width: 2px !important;
        background: #444444 !important;
        border-radius: 0px !important;
    }
    @media screen and (min-width:1025px){
        .scrollbar-track-y{
            width: 0.75rem !important;
            box-shadow: inset 1px 0 0px rgba(0, 0, 0, 0.1);
            background-color: #cccccc !important;
        }
        .scrollbar-thumb {
            width: 0.75rem !important;
            background: #444444 !important;
            border-radius: 0px !important;
        }
    }

    #___gatsby{
        width: 100%;
        height: 100%;
    }

    #___gatsby > div {
        width: 100%;
        height: 100%;
        overflow:hidden;
    }

    #gatsby-focus-wrapper:focus{
        outline:none;
    }
    
    #main{
        position:relative;
    }

    & br.desktop{
        @media screen and (max-width:1368px){
            display:none;
        }
    }
    #didomi-host{
        font-family:'Calibre' !important;
        font-size:16px !important;
    }
    #didomi-host b:first-of-type{
        font-size:18px !important;
    }
    
    .didomi-components-button{
        font-weight:bold !important;
    }
`;
