import { ScrollbarPlugin } from "smooth-scrollbar";

class DisableScrollPlugin extends ScrollbarPlugin {
  static pluginName = "disable";

  static defaultOptions = {
    disabled: false,
  };

  transformDelta(delta) {
    return this.options.disabled ? { x: 0, y: 0 } : delta;
  }
}

export default DisableScrollPlugin;
