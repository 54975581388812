import * as easings from "d3-ease";

import { PoseableColumn, PoseableFlex } from "components/poseable";

import BGGrid from "components/BGGrid";
import { Container } from "components/layout";
import PropTypes from "prop-types";
import React from "react";
import posed from "react-pose";
import styled from "styled-components";

const Bg = styled(
  posed(PoseableFlex)({
    init: {
      display: `flex`,
      zIndex: 1000,
      transition: {
        type: "tween",
        duration: 0,
      },
    },
    closed: {
      applyAtEnd: {
        display: `none`,
      },
      transition: {
        type: "tween",
        duration: 1,
      },
      afterChildren: true,
    },
    open: {
      applyAtStart: {
        display: `flex`,
      },
      transition: {
        type: "tween",
        duration: 1,
      },
      beforeChildren: true,
    },
  })
)`
  flex-wrap: nowrap;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
  pointer-events: none;

  & > div {
    height: 100%;
  }
`;

const BgColumn = styled(posed(PoseableColumn)())`
  position: absolute !important;
  display: block;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  will-change: transform;
  transform: translateX(${(props) => props.tx * 100}%) translateZ(0px);
`;

const Blind = posed.figure({
  init: {
    x: `-5%`,
  },
  open: {
    applyAtStart: {
      visibility: `visible`,
      x: `105%`,
    },
    x: `-5%`,
    transition: {
      type: "tween",
      duration: 400,
      ease: easings.easeSinIn,
    },
  },
  closed: {
    x: `-105%`,
    applyAtEnd: {
      visibility: `hidden`,
    },
    transition: {
      type: "tween",
      duration: 400,
      ease: easings.easeSinIn,
    },
  },
});

const getMobileLines = (props) => {
  const { bgColor } = props;
  return BGGrid.MOBILE_ARR.map((n, idx) => {
    return (
      <BgColumn
        key={`bggrid-line-mobile-${idx}`}
        idx={idx}
        width={1 / 4}
        tx={-2 + idx}
      >
        <Blind
          style={{
            backgroundColor: bgColor,
            position: `absolute`,
            display: `block`,
            top: 0,
            left: 0,
            width: `110%`,
            height: `100%`,
            backfaceVisibility: `hidden`,
            willChange: `transform`,
          }}
        />
      </BgColumn>
    );
  });
};

const getDesktopLines = (props) => {
  const { bgColor } = props;
  return BGGrid.DESKTOP_ARR.map((n, idx) => {
    return (
      <BgColumn
        key={`bggrid-line-desktop-${idx}`}
        idx={idx}
        width={`calc(${(1 / 6) * 100}% + 1px)`}
        tx={-4.5 + idx}
      >
        <Blind
          style={{
            backgroundColor: bgColor,
            position: `absolute`,
            display: `block`,
            top: 0,
            left: 0,
            width: `110%`,
            height: `100%`,
            backfaceVisibility: `hidden`,
            willChange: `transform`,
          }}
        />
      </BgColumn>
    );
  });
};

export default class AnimatedGrid extends BGGrid {
  static propTypes = {
    mobile: PropTypes.bool,
    position: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    z: PropTypes.number,
    showing: PropTypes.bool,
    first: PropTypes.bool,
  };

  static defaultProps = {
    mobile: false,
    position: null,
    height: null,
    z: -1,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bgColor !== prevState.bgColor) {
      return {
        mobileLines: getMobileLines(nextProps),
        desktopLines: getDesktopLines(nextProps),
        bgColor: nextProps.bgColor,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      mobile: props.mobile === true,
      mobileLines: getMobileLines(props),
      desktopLines: getDesktopLines(props),
      showing: true,
    };
  }

  render() {
    const {
      mobile = false,
      position = null,
      height = null,
      z = -1,
      showing,
      first,
    } = this.props;

    const { mobileLines, desktopLines } = this.state;

    return (
      <Bg
        aria-hidden="true"
        initialPose={`init`}
        pose={first ? `init` : showing ? `open` : `closed`}
        style={{
          zIndex: z,
          display: z >= 0 ? `flex` : `none`,
          position: position || `fixed`,
          height: height || `calc(100vh + 100px)`,
        }}
      >
        <Container
          style={{
            overflow: `visible`,
            transform: `translateZ(0)`,
          }}
        >
          {mobile ? mobileLines : desktopLines}
        </Container>
      </Bg>
    );
  }
}
