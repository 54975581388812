import React, { PureComponent } from "react";
import GatsbyImage from "./GatsbyImage";

export default class Img extends PureComponent {
  render() {
    let {
      ref = null,
      alt = "",
      critical = false,
      fadeIn = false,
      ioMargin = undefined,
      ...rest
    } = this.props;

    return (
      <GatsbyImage
        ref={ref}
        alt={alt}
        critical={critical === true}
        fadeIn={fadeIn}
        durationFadeIn={300}
        ioMargin={
          typeof window === `undefined`
            ? `300px`
            : ioMargin === undefined
            ? window.innerWidth < 768
              ? `600px`
              : `300px`
            : ioMargin
        }
        {...rest}
      />
    );
  }
}
