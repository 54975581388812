import React, { forwardRef } from 'react';
import { Box, Flex } from '@rebass/grid';
import { Button, Text, Heading } from 'rebass';
import { Column, Row, Container } from 'components/layout';
import posed from 'react-pose';
import Img from 'components/Img';

const poseable = Cmp =>
  forwardRef(({ hoverable, focusable, visibledelay, ...props }, ref) => (
    <Cmp ref={ref} {...props} />
  ));

export const PoseableFlex = poseable(Flex);
export const PoseableBox = poseable(Box);
export const PoseableButton = poseable(Button);
export const PoseableText = poseable(Text);
export const PoseableHeading = poseable(Heading);
export const PoseableColumn = poseable(Column);
export const PoseableRow = poseable(Row);
export const PoseableContainer = poseable(Container);
export const PosableImg = poseable(Img);

// defaults
export const DefaultPosedFlex = posed(PoseableFlex)({});
export const DefaultPosedBox = posed(PoseableBox)({});
export const DefaultPosedButton = posed(PoseableButton)({});
export const DefaultPosedText = posed(PoseableText)({});
export const DefaultPosedHeading = posed(PoseableHeading)({});
export const DefaultPosedColumn = posed(PoseableColumn)({});
export const DefaultPosedRow = posed(PoseableRow)({});
export const DefaultPosedContainer = posed(PoseableContainer)({});
