import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mitt from 'mitt';
import events from 'utils/events';
import GlobalEmitter from 'utils/GlobalEmitter';

const Listener = styled.div`
  ${props =>
    props.theme.breakpoints.map((bp, idx) => {
      if (idx === 0) {
        return `content: '0';
      column-gap: ${props.theme.space[0]}px;`;
      }

      const style = `@media screen and (min-width:${bp}){
        content:"${idx}";
        column-gap: ${props.theme.space[idx]}px;}`;
      return style;
    })};
`;

export default class BreakpointListener extends PureComponent {
  /**
   *
   *
   * @static
   * @type {Number}
   * @memberof BreakpointListener
   */
  static size = 5;

  /**
   *
   *
   * @static
   * @type {mitt.MittStatic}
   * @memberof BreakpointListener
   */
  static emitter;

  /**
   *
   * @type {HTMLElement}
   * @memberof BreakpointListener
   */
  __el;

  static propTypes = {
    theme: PropTypes.shape({
      space: PropTypes.arrayOf(PropTypes.number).isRequired,
      breakpoints: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    defaultBreakpoint: PropTypes.number.isRequired
  };

  static defaultProps = {
    defaultBreakpoint: 5
  };

  static emit(type, data) {
    if (!BreakpointListener.emitter) {
      BreakpointListener.emitter = mitt();
    }
    BreakpointListener.emitter.emit(type, data);
  }

  static on(type, cb) {
    if (!BreakpointListener.emitter) {
      BreakpointListener.emitter = mitt();
    }
    return BreakpointListener.emitter.on(type, cb);
  }

  static off(type, cb) {
    if (!BreakpointListener.emitter) {
      return;
    }
    return BreakpointListener.emitter.off(type, cb);
  }

  state = {
    breakpoint: null
  };

  componentDidMount() {
    GlobalEmitter.on(events.resize, this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.resize, this.onResize);
  }

  getBreakpointData = () => {
    if (typeof window === `undefined` || !this.__el) {
      return this.props.defaultBreakpoint;
    }

    let size = parseInt(
      window.getComputedStyle(this.__el).content.replace(/"/g, '')
    );

    let gap = parseFloat(
      window.getComputedStyle(this.__el).columnGap.replace(/"/g, '')
    );

    BreakpointListener.gap = gap;
    BreakpointListener.size = size;

    return { size, gap };
  };

  onResize = () => {
    let bpData = this.getBreakpointData();
    let oldSize = this.state.breakpoint;
    let newSize = bpData.size;

    if (newSize === oldSize) {
      return;
    }

    this.setState({ breakpoint: newSize }, () => {
      BreakpointListener.size = newSize;
      BreakpointListener.emit(events.breakpoint, {
        oldSize,
        newSize,
        gap: bpData.gap
      });
    });
  };

  render() {
    return (
      <Listener
        theme={this.props.theme}
        id="bp-listener"
        ref={r => (this.__el = r)}
      />
    );
  }
}
