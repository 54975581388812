export default {
  breakpoint: "breakpoint",
  resize: "resize",
  resizeScrollbar: "resizeScrollbar",
  size: "size",
  pageReady: "pageReady",
  pageExit: "pageExit",
  videoPlayed: "videoPlayed",
  audioPlayed: "audioPlayed",
  scrolled: "scrolled",
  scrollTo: "scrollTo",
  scrollIntoView: "scrollIntoView",
  enableScroll: "enableScroll",
  disableScroll: "disableScroll",
};
